import React from 'react';
import toastr from 'toastr';

class ContactForm extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { name, email, phone, subject, message } = this.state;

    window.fetch('https://onsite-machine-functions.azurewebsites.net/api/ContactFunction', {
      method: 'post',
      body: JSON.stringify({ name, email, phone, subject, message }),
    }).then(function (response) {
      return response.json();
    }).then(function () {
      toastr.success('Your message was sent successfully. We will reach out to you in short order.');
    })
      .catch(() => {
        toastr.error('An unknown error has occurred, please try again...');
      });
  }

  render () {
    const { name, email, phone, subject, message } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="row">
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder="Name..." value={name} onChange={(e) => this.setState({ name: e.target.value })} required />
          </div>
          <div className="form-group col-md-6">
            <input type="email" className="form-control" placeholder="Email..." value={email} onChange={(e) => this.setState({ email: e.target.value })} required />
          </div>
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder="Phone..." value={phone} onChange={(e) => this.setState({ phone: e.target.value })} required />
          </div>
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder="Subject..." value={subject} onChange={(e) => this.setState({ subject: e.target.value })} required />
          </div>
          <div className="form-group col-md-12">
            <textarea className="form-control" rows={6} placeholder="Brief description of your needs..." value={message} onChange={(e) => this.setState({ message: e.target.value })} defaultValue="" />
          </div>
        </div>
        <button type="submit" className="btn btn-default btn-primary bold">Request a Quote</button>
      </form>
    );
  }
}

export default ContactForm;
